<template>
  <div class="h100">
    <div class="small-header">
      <img :src="data.logoImagePath" alt="" />
    </div>

    <div class="about">
      <p>
        The Federation of Polish Food Banks is a public benefit organization
        uniting 32 Food Banks across Poland. Each of them collects unsold good
        quality food products from stores and producers on a daily basis, to
        subsequently forward them to aid organizations, e. g. soup kitchens,
        social welfare homes, community centers, hospices, orphanages, or
        support centers for single mothers.
        <br /><br />
        The Banks also operate the Food Aid Operational Program, co-financed by
        EU funds. In 2020 alone, 64 thousand tons of foodstuffs were delivered
        to people in need. The Federation of Polish Food Banks is also involved
        in education and activities aimed at raising public awareness about the
        consequences of wasting food and ways to prevent this phenomenon.
        <br /><br />
        More:
        <a href="https://bankizywnosci.pl" _target="blank"
          >www.bankizywnosci.pl</a
        >
      </p>
    </div>

    <router-link
      class="about-paybtn"
      :to="{ name: 'Payment', params: { foundation: JSON.stringify(data) } }"
      >SUPPORT US</router-link
    >

    <div class="navbar">
      <router-link :to="{ name: 'Home' }"
        ><img :src="require('@/assets/img/home_btn.png')" alt=""
      /></router-link>
    </div>
  </div>
</template>



<script>
export default {
  props: ["foundation"],
  data() {
    return {
      data: this.$store.getters.getFoundationById(
        this.$route.params.foundationId
      ),
    };
  },
  mounted() {
    console.log(this.foundation);
  },
};
</script>
