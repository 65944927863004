<template>
  <div id="app-container" class="row align-items-center justify-content-center">
    <div class="terminal-bg">
      <section id="terminal">
        <router-view />
      </section>
    </div>
    <div class="text">
      <h2>
        <a href="https://donateo.pl/" target="_blank"
          ><img
            class="donateo-logo"
            :src="require('@/assets/img/logo-donateo.svg')" /></a
        ><br /><span>#StandWithUkraine</span>
      </h2>
      <p>
        Over 3.1 million people have fled from Ukraine to Poland seeking
        shelter.<br /><br />
        The Federation of Polish Food Banks is providing food aid to those in
        need.
      </p>
      <br />
      <h1>
        By donating to our cause, you will help us buy essential food supplies
        which will be transported to people in Ukraine.
      </h1>
      <br />
      <p>
        Your charitable contribution will be transferred directly to the Polish
        Food Banks’ account.
      </p>
      <img :src="require('@/assets/img/logo.jpg')" alt="" />
    </div>
    <div class="mobile-text" v-if="showText">
      <div>
        <img :src="require('@/assets/img/logo.jpg')" alt="" />
      </div>
      <h2>
        <a href="https://donateo.pl/" target="_blank"
          ><img
            class="donateo-logo"
            :src="require('@/assets/img/logo-donateo.svg')" /></a
        ><br /><span>#StandWithUkraine!</span>
      </h2>
      <p>
        Over 3.1 million people have fled from Ukraine to Poland seeking
        shelter.<br /><br />
        The Federation of Polish Food Banks is providing food aid to those in
        need.
      </p>
      <br />
      <h1>
        By donating to our cause, you will help us buy essential food supplies
        which will be transported to people in Ukraine.
      </h1>
      <br />
      <p>
        Your charitable contribution will be transferred directly to the Polish
        Food Banks’ account.
      </p>
    </div>
    <div class="ibtn" v-on:click="showText = !showText">
      <img :src="require('@/assets/img/i_btn.png')" alt="" v-if="!showText" />
      <img :src="require('@/assets/img/x_btn.png')" alt="" v-if="showText" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showText: false,
    };
  },
};
</script>

<style lang="scss">
@import "./assets/styles/App.css";
</style>
